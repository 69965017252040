<template>
  <svg width="231" height="26" xmlns="http://www.w3.org/2000/svg">
    <!-- Created with SVG Editor - http://github.com/mzalive/SVG Editor/ -->
    <defs>
      <filter height="200%" width="200%" y="-50%" x="-50%" id="svg_8_blur">
        <feGaussianBlur stdDeviation="0.6" in="SourceGraphic" />
      </filter>
    </defs>
    <g>
      <title>background</title>
      <rect
        fill="none"
        id="canvas_background"
        height="28"
        width="233"
        y="-1"
        x="-1"
      />
      <g
        display="none"
        overflow="visible"
        y="0"
        x="0"
        height="100%"
        width="100%"
        id="canvasGrid"
      >
        <rect
          fill="url(#gridpattern)"
          stroke-width="0"
          y="0"
          x="0"
          height="100%"
          width="100%"
        />
      </g>
    </g>
    <g>
      <title>Layer 1</title>
      <path
        d="m280.5,281.4375c0,0 -2.054138,0.567322 -5,0c-5.287994,-1.018372 -7,-2 -8,-3c-1,-1 -1.289795,-3.042908 -1,-4c1.04483,-3.450836 4.891724,-5.19577 12,-9c7.885925,-4.220428 13.207825,-6.930664 21,-9c2.899506,-0.770004 4,-1 3,-1c-5,0 -21,0 -40,0c-7,0 -17,-2 -17,0c0,4 10.003876,0.232941 17,0c15.024963,-0.500275 22.824158,1.062897 30,-3c1.230652,-0.696777 -3,-1 -9,-1c-13,0 -21,0 -24,1l-2,1"
        id="svg_2"
        stroke-width="1.5"
        stroke="#fff"
        fill="none"
      />
      <path
        d="m192.5,194.4375c1,0 2.74707,1.61702 7,3c5.784607,1.881058 9.04863,2.814651 17,5c10.067017,2.766815 21,5 27,5c8,0 12,0 19,1l5,0l2,0"
        id="svg_3"
        stroke-width="1.5"
        stroke="#fff"
        fill="none"
      />
      <path
        d="m251.5,198.4375c-1,0 -1.934143,0.144287 -4,1c-5.843124,2.420303 -8.925797,2.497559 -14,3c-5.970795,0.591232 -11,0 -13,0c-4,0 -7,0 -9,0l-1,0l-2,0l0,-1"
        id="svg_4"
        stroke-width="1.5"
        stroke="#fff"
        fill="none"
      />
      <text
        font-style="normal"
        font-weight="normal"
        filter="url(#svg_8_blur)"
        opacity="0.78"
        xml:space="preserve"
        text-anchor="start"
        font-family="Helvetica, Arial, sans-serif"
        font-size="26"
        id="svg_8"
        y="21.4375"
        x="27"
        stroke="#000ecc"
        fill="#ffffff"
      >
        CYCLE-RIDER
      </text>
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useColors } from "vuestic-ui";

const { getColor } = useColors();

const props = withDefaults(
  defineProps<{
    height?: number;
    start?: string;
    end?: string;
  }>(),
  {
    height: 18,
    start: "primary",
    end: undefined,
  },
);

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  };
});
</script>
